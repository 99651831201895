<template>
  <div>
    <div class="cen">
      <span style="font-size: 18px;font-weight: 400;color: #ffffff;">销售总额</span>
      <div class="jinebg" v-for="(item,index) in comount.actual_payment" :key="index">
        <span style="font-size: 43px;font-weight: 700;color: #ffffff;">{{item}}</span>
      </div>
      <!--<div class="jinebg">-->
      <!--  <span style="font-size: 43px;font-weight: 700;color: #ffffff;">7</span>-->
      <!--</div>-->
      <!--<div class="jinebg">-->
      <!--  <span style="font-size: 43px;font-weight: 700;color: #ffffff;">6</span>-->
      <!--</div>-->
      <!--<div class="jinebg">-->
      <!--  <span style="font-size: 43px;font-weight: 700;color: #ffffff;">7</span>-->
      <!--</div>-->
      <!--<div class="jinebg">-->
      <!--  <span style="font-size: 43px;font-weight: 700;color: #ffffff;">4</span>-->
      <!--</div>-->
      <!--<div class="jinebg">-->
      <!--  <span style="font-size: 43px;font-weight: 700;color: #ffffff;">0</span>-->
      <!--</div>-->
      <div>
        <div style="margin-bottom: 5px;">
          <!--<span style="opacity: 0.6;font-size: 14px;font-weight: 400;color: #e9ffff;margin-right: 10px;">同比增长</span>-->
          <!--<img src="@/assets/images/daping/zengzhang.png" alt="" style="width: 4px;height: 9px;margin-right: 5px;">-->
          <!--<span style="font-size: 14px;font-weight: 500;color: #ff463c;">25%</span>-->
        </div>
        <div style="font-size: 18px;font-weight: 400;color: #ffffff;">元</div>
      </div>
    </div>

    <!--地图-->
    <div>
      <div class="bg" :style="{height:'700px',width:'100%'}" ref="myEchart"></div>
    </div>

  </div>
</template>

<script>
import echarts from "echarts";
import "echarts/map/js/china"; // 引入中国地图数据
import zhongguo from "@/assets/mapJson/data-city.json"
import {total_amount,replacement} from "@/api/yunli";

export default {
  data(){
    return{
      myChart: null,
      geoCoordMap: {
        海门: [121.15, 31.89],
        鄂尔多斯: [109.781327, 39.608266],
        招远: [120.38, 37.35],
        舟山: [122.207216, 29.985295],
        齐齐哈尔: [123.97, 47.33],
        盐城: [120.13, 33.38],
        赤峰: [118.87, 42.28],
        青岛: [120.33, 36.07],
        乳山: [121.52, 36.89],
        金昌: [102.188043, 38.520089],
        泉州: [118.58, 24.93],
        莱西: [120.53, 36.86],
        日照: [119.46, 35.42],
        胶南: [119.97, 35.88],
        南通: [121.05, 32.08],
        拉萨: [91.11, 29.97],
        云浮: [112.02, 22.93],
        梅州: [116.1, 24.55],
        文登: [122.05, 37.2],
        上海: [121.48, 31.22],
        攀枝花: [101.718637, 26.582347],
        威海: [122.1, 37.5],
        承德: [117.93, 40.97],
        厦门: [118.1, 24.46],
        汕尾: [115.375279, 22.786211],
        潮州: [116.63, 23.68],
        丹东: [124.37, 40.13],
        太仓: [121.1, 31.45],
        曲靖: [103.79, 25.51],
        烟台: [121.39, 37.52],
        福州: [119.3, 26.08],
        瓦房店: [121.979603, 39.627114],
        即墨: [120.45, 36.38],
        抚顺: [123.97, 41.97],
        玉溪: [102.52, 24.35],
        张家口: [114.87, 40.82],
        阳泉: [113.57, 37.85],
        莱州: [119.942327, 37.177017],
        湖州: [120.1, 30.86],
        汕头: [116.69, 23.39],
        昆山: [120.95, 31.39],
        宁波: [121.56, 29.86],
        湛江: [110.359377, 21.270708],
        揭阳: [116.35, 23.55],
        荣成: [122.41, 37.16],
        连云港: [119.16, 34.59],
        葫芦岛: [120.836932, 40.711052],
        常熟: [120.74, 31.64],
        东莞: [113.75, 23.04],
        河源: [114.68, 23.73],
        淮安: [119.15, 33.5],
        泰州: [119.9, 32.49],
        南宁: [108.33, 22.84],
        营口: [122.18, 40.65],
        惠州: [114.4, 23.09],
        江阴: [120.26, 31.91],
        蓬莱: [120.75, 37.8],
        韶关: [113.62, 24.84],
        嘉峪关: [98.289152, 39.77313],
        广州: [113.23, 23.16],
        延安: [109.47, 36.6],
        太原: [112.53, 37.87],
        清远: [113.01, 23.7],
        中山: [113.38, 22.52],
        昆明: [102.73, 25.04],
        寿光: [118.73, 36.86],
        盘锦: [122.070714, 41.119997],
        长治: [113.08, 36.18],
        深圳: [114.07, 22.62],
        珠海: [113.52, 22.3],
        宿迁: [118.3, 33.96],
        咸阳: [108.72, 34.36],
        铜川: [109.11, 35.09],
        平度: [119.97, 36.77],
        佛山: [113.11, 23.05],
        海口: [110.35, 20.02],
        江门: [113.06, 22.61],
        章丘: [117.53, 36.72],
        肇庆: [112.44, 23.05],
        大连: [121.62, 38.92],
        临汾: [111.5, 36.08],
        吴江: [120.63, 31.16],
        石嘴山: [106.39, 39.04],
        沈阳: [123.38, 41.8],
        苏州: [120.62, 31.32],
        茂名: [110.88, 21.68],
        嘉兴: [120.76, 30.77],
        长春: [125.35, 43.88],
        胶州: [120.03336, 36.264622],
        银川: [106.27, 38.47],
        张家港: [120.555821, 31.875428],
        三门峡: [111.19, 34.76],
        锦州: [121.15, 41.13],
        南昌: [115.89, 28.68],
        柳州: [109.4, 24.33],
        三亚: [109.511909, 18.252847],
        自贡: [104.778442, 29.33903],
        吉林: [126.57, 43.87],
        阳江: [111.95, 21.85],
        泸州: [105.39, 28.91],
        西宁: [101.74, 36.56],
        宜宾: [104.56, 29.77],
        呼和浩特: [111.65, 40.82],
        成都: [104.06, 30.67],
        大同: [113.3, 40.12],
        镇江: [119.44, 32.2],
        桂林: [110.28, 25.29],
        张家界: [110.479191, 29.117096],
        宜兴: [119.82, 31.36],
        北海: [109.12, 21.49],
        西安: [108.95, 34.27],
        金坛: [119.56, 31.74],
        东营: [118.49, 37.46],
        牡丹江: [129.58, 44.6],
        遵义: [106.9, 27.7],
        绍兴: [120.58, 30.01],
        扬州: [119.42, 32.39],
        常州: [119.95, 31.79],
        潍坊: [119.1, 36.62],
        重庆: [106.54, 29.59],
        台州: [121.420757, 28.656386],
        南京: [118.78, 32.04],
        滨州: [118.03, 37.36],
        贵阳: [106.71, 26.57],
        无锡: [120.29, 31.59],
        本溪: [123.73, 41.3],
        克拉玛依: [84.77, 45.59],
        渭南: [109.5, 34.52],
        马鞍山: [118.48, 31.56],
        宝鸡: [107.15, 34.38],
        焦作: [113.21, 35.24],
        句容: [119.16, 31.95],
        北京: [116.46, 39.92],
        徐州: [117.2, 34.26],
        衡水: [115.72, 37.72],
        包头: [110, 40.58],
        绵阳: [104.73, 31.48],
        乌鲁木齐: [87.68, 43.77],
        枣庄: [117.57, 34.86],
        杭州: [120.19, 30.26],
        淄博: [118.05, 36.78],
        鞍山: [122.85, 41.12],
        溧阳: [119.48, 31.43],
        库尔勒: [86.06, 41.68],
        安阳: [114.35, 36.1],
        开封: [114.35, 34.79],
        济南: [117, 36.65],
        德阳: [104.37, 31.13],
        温州: [120.65, 28.01],
        九江: [115.97, 29.71],
        邯郸: [114.47, 36.6],
        临安: [119.72, 30.23],
        兰州: [103.73, 36.03],
        沧州: [116.83, 38.33],
        临沂: [118.35, 35.05],
        南充: [106.110698, 30.837793],
        天津: [117.2, 39.13],

      },
      dataArr: [
        { name: "海门", value: 9 },
        { name: "鄂尔多斯", value: 12 },
        { name: "招远", value: 12 },
        { name: "舟山", value: 12 },
        { name: "齐齐哈尔", value: 14 },
        { name: "盐城", value: 15 },
        { name: "赤峰", value: 16 },
        { name: "曲靖", value: 27 },
        { name: "莱州", value: 32 },
        { name: "湖州", value: 32 },
        { name: "汕头", value: 32 },
        { name: "昆山", value: 33 },
        { name: "宁波", value: 33 },
        { name: "湛江", value: 33 },
        { name: "揭阳", value: 34 },
        { name: "咸阳", value: 33 },
        { name: "西安", value: 201 },
        { name: "铜川", value: 44 },
        { name: "平度", value: 44 },
        { name: "佛山", value: 44 },
        { name: "海口", value: 44 },
        { name: "江门", value: 45 },
        { name: "章丘", value: 45 },
        { name: "肇庆", value: 46 },
        { name: "张家港", value: 52 },
        { name: "三门峡", value: 53 },
        { name: "锦州", value: 54 },
        { name: "南昌", value: 54 },
        { name: "柳州", value: 54 },
        { name: "三亚", value: 54 },
        { name: "自贡", value: 56 },
        { name: "吉林", value: 56 },
        { name: "阳江", value: 57 },
        { name: "泸州", value: 57 },
        { name: "西宁", value: 57 },
        { name: "宜宾", value: 58 },
        { name: "呼和浩特", value: 58 },
        { name: "成都", value: 58 },
        { name: "大同", value: 58 },
        { name: "扬州", value: 64 },
        { name: "常州", value: 64 },
        { name: "潍坊", value: 65 },
        { name: "重庆", value: 66 },
        { name: "台州", value: 67 },
        { name: "南京", value: 67 },
        { name: "滨州", value: 70 },
        { name: "衡水", value: 80 },
        { name: "包头", value: 80 },
        { name: "绵阳", value: 80 },
        { name: "乌鲁木齐", value: 84 },
        { name: "枣庄", value: 84 },
        { name: "杭州", value: 84 },
        { name: "淄博", value: 85 },
        { name: "鞍山", value: 86 },
        { name: "溧阳", value: 86 },
        { name: "库尔勒", value: 86 },
        { name: "安阳", value: 90 },
        { name: "开封", value: 90 },
        { name: "济南", value: 92 },
        { name: "富阳", value: 106 },
        { name: "泰安", value: 112 },
        { name: "诸暨", value: 112 },
        { name: "郑州", value: 113 },
        { name: "哈尔滨", value: 204 }
      ],

      //销售总额
      comount:{},

      institution_code: localStorage.getItem('institution_code'),

    }
  },

  created () {

  },

  mounted() {
    let _this = this;
    _this.chinaConfigure();
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     _this.chinaConfigure();
    //   }, 2000);
    // });

    // localStorage.setItem('institution_code','');

    if(localStorage.getItem('institution_code') == '' || localStorage.getItem('institution_code') == undefined || localStorage.getItem('institution_code') == null){
      console.log(123465123)
      this.getCode()
    }else {
      console.log(456456465)
      //获取销售总额接口
      this.gettotal_amount();
    }

    console.log(localStorage.getItem('institution_code'),'123132132123');

  },

  beforeDestroy() {
    if (!this.myChart) {
      return;
    }
    this.myChart.clear();
    this.myChart = null;
  },

  methods:{
    // 置换机构码
    getCode(){
      replacement().then(res => {
        console.log(res);
        //大屏标题
        this.$emit('title',res.data.company_name);
        // 机构码
        this.institution_code = res.data.institution_code;
        localStorage.setItem('institution_code',res.data.institution_code);

        this.gettotal_amount();
      })
    },

    //获取销售总额接口
    gettotal_amount(){
      total_amount(localStorage.getItem('institution_code')).then(res => {
        console.log(res,'销售总额');
        this.comount = res.data;
        //大屏标题
        this.$emit('title',res.data.company_name);
        //转字符串用于页面循环
        this.comount.actual_payment = this.comount.actual_payment.toString();
      })
    },

    convertData(data) {
      var res = [];
      let self = this;
      for (var i = 0; i < data.length; i++) {
        var geoCoord = self.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          });
        }
      }
      console.log(res)
      return res;
    },
    chinaConfigure() {
      let self = this;
      this.myChart = echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置
      window.onresize = this.myChart.resize;

      this.myChart.setOption({
        // 进行相关配置
        // backgroundColor: "rgba(0, 0, 0, 0)", //地图背景色
        geo: {
          map: "china",
          // 禁止地图放大缩小
          roam: false,
          zoom: 1,
          label: {
            // 通常状态下的样式
            normal: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            // 鼠标放上去的样式
            emphasis: {
              textStyle: {
                color: "#fff",
              },
            },
          },
          // 地图区域的样式设置
          itemStyle: {
            normal: {
              borderColor: "rgba(147, 235, 248, 1)",
              borderWidth: 1,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
              shadowColor: "rgba(128, 217, 248, 1)",
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
            },
            // 鼠标放上去高亮的样式
            emphasis: {
              areaColor: "#389BB7",
              borderWidth: 0,
            },
          },
        },
        //滑动显示数据
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            console.log(params)
            return params.name + " 已接入: " + params.data.value[2];
          }
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '10%',
          width:'auto',
          height: '100%'
        },
        series: [
          // {
          //   name: "散点",
          //   type: "effectScatter",
          //   coordinateSystem: "geo",
          //   color: "rgba(255,255,0,.5)", //点的颜色
          //   data: self.convertData(self.dataArr),
          //   // symbolSize: 10, //点的大小
          //   symbolSize: function(parmas) {
          //     console.log(parmas)
          //     //显示打点的值，即点的大小根据一个方法除数取整
          //     return Math.ceil(parmas[2] / 10);
          //   },
          //   symbol: "circle", //点的样式
          //   cursor: "pointer", //鼠标放上去的效果
          //   label: {
          //     normal: {
          //       //默认展示
          //       show: false
          //     },
          //     emphasis: {
          //       //滑过展示
          //       show: false
          //     }
          //   },
          //   itemStyle: {
          //     emphasis: {
          //       borderColor: "#4c60ff",
          //       borderWidth: 5
          //     }
          //   }
          // },
          {
            type: "map",
            map: "china",
            geoIndex: 0,
            aspectScale: 0.75,
            tooltip: {
              show: false
            }
          }
        ]
      });
    }
  },

  watch: {
    screenWidth: function(el) {
      let _this = this;
      if (this.myChart) {
        this.myChart.dispose();
      }

      setTimeout(() => {
        _this.chinaConfigure();
      }, 2000);
    }
  }

}
</script>

<style lang="scss">
.cen{
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.jinebg{
  width: 65px;
  height: 83px;
  background-image: url("../../../assets/images/daping/qian.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-right: 15px;
}

.bg {
  height: 100%;
  background-size: 100% 100%;
}
.map {
  position: relative;
  height: 100%;
  z-index: 1;
}
.map4 {
  position: absolute;
  left: 0;
  top: 10%;
  margin-top: 0.2rem;
  z-index: 5;
}

.map2 {
  position: absolute;
  left: 0;
  top: 10%;
  margin-top: 0.2rem;
  z-index: 3;
  opacity: 0.2;
  width: 100%;
  animation: myfirst 10s infinite linear;
}

@keyframes myfirst {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}


</style>
